import React, { useEffect, useState, useRef } from 'react';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin, Progress, List} from 'antd';
import { get, find, map, size, filter,  reduce, result } from 'lodash';
import { MONTH_LIST, CHECKIN_REPORT_FILTER_TYPES, STATUS_CODE, } from 'common/Constants';
import { addDaysinDate, dateInDetail, getMonthDateRange, getKenyanDateTime, addMonthinDate, getMySqlDate, momentDate, getinitialdate } from 'utils/Date';
import { Row, Col, Table, Select, DatePicker, Space, Form, Tooltip, Modal, message, Button } from 'antd';
import moment from 'moment';
import { DEVICE } from 'common/Device';
import { CALL_API } from 'common/API';
import { CSVLink } from "react-csv";
import {
	getUserInfo,
	getUserList,
  getIsCheckedIn
} from 'redux/selectors';
import { connect } from 'react-redux';

const StyledTitle = styled.div`
    font-size: 0.9rem;
    font-weight: bold;
    color: #141414;
    margin-bottom: 10px;
`;


const StyledApprovalSearchForm = styled(Form)`
  .ant-form-item-with-help {
    margin-bottom: 0px;
    .ant-form-item-explain-connected {
      display: none;
    }
  }
  .ant-form-item {
    display: inline-block;
  }
  .outer-form {
    margin-right: 0px;
  }
  .ant-form-item{
    margin-bottom: 5px;
  }
  .ant-btn{
    height: 29px;
    margin-top: 2px;
    margin-left: 0px;
    padding: 0px 10px 2px 10px;
  }
`;

const StyledButton3 = styled(Button)`
  width: 120px;
  border: 1px solid ${COLORS.PRIMARY};
  color:  ${COLORS.PRIMARY};
  width: initial;
  background:transparent;
`;
const StyleRow = styled(Row)`
  margin-top:10px;
`;


function UserCheckinReport({
  leaveCategoryList,
  userInfo,
  userList,
  isCheckedIn,
}) {
  const StyledChartContainer = styled.div`

  min-height: ${(leaveCategoryList.find(category => category.leave_category_id === 3)) ? '705px' : '705px'};
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 25px;
  .select-width{
    width: 172px;
  }
  .name-title {
    display:flex;
    justify-content:space-between;
    font-size: 14px;
    padding-bottom: 15px;
  }
  .list-style{
    height: calc(100% - 20px);
    overflow: auto;
    margin-top: 1%;
    margin-bottom: 1%;
  }
  .span-color{
    color:blue;
  }
  .styled-col{
    height: 305px;
    margin-top: 5px;
  }
  .date_picker{
    width:140px;
  }
  .dash-line{
    width:70%;
    border-bottom:1px dashed #A8A8A8;
  }
  .title{
    padding:10px;
    font-weight:bold;
  }
  @media ${DEVICE.tablet}  {
    .dash-line {
      width:45%;
      border-bottom:1px dashed #A8A8A8;
    }
    .name-title {
      display:flex;
      flex-direction:column;
      justify-content:space-between;
      padding-bottom: 15px;
    }
    .name-title p{
      text-align:right;
    }
  }
`;

  const [ loading, setLoading ] = useState(false)
  const [ form ] = Form.useForm(); 
  const [ checkinDetail, setCheckinDetail ] = useState({})
  const [ avg, setAvg ] = useState(0)
  const [ ontime,setOntime] = useState(0)
  const [ totalDays, setTotalDays] = useState(0)
  const [monthList, setMonthList] = useState([])

  const [CSVcheckinList, setCSVcheckinList] = useState([]);
  const csvLinkEl = useRef();
  const shiftStartTime = result(find(userList, {  'user_id': userInfo?.employer_id , 'role_id' : 4}), 'shift_start_time');
  const CSVHeaders = [
    { label: "Date" },
    { label: "Check-in time" },
  ];

  const curr_month = moment().month() + 1

  const list = () => {
    let month_list = []
    for (let i = 0; i < curr_month; i++) {
      month_list.push(MONTH_LIST[i])
    }
    setMonthList(month_list)
  }
  useEffect(() => {
    list()
    getCheckinList()
  },[isCheckedIn])
  
  const getCheckinList = async () => {
    return new Promise(async (resolve, reject) => {
    const {
      duration_type,
    } = form.getFieldsValue();

    const start_date = form.getFieldValue('start_date')
    const end_date = form.getFieldValue('end_date')
    const { startDateOfMonth, lastDateOfMonth } = getMonthDateRange(
      dateInDetail(getKenyanDateTime()).year,
      get(find(MONTH_LIST, { value:   form.getFieldValue('month') }), 'index', 0)
    );
    setLoading(true)
    const {checkIn}= await CALL_API('employeelogin-detail', 'post',{
      start_date: getMySqlDate(duration_type === 2 ? startDateOfMonth : (duration_type === 4 ? momentDate(addMonthinDate(getinitialdate(),)) : start_date)),
      end_date: getMySqlDate(duration_type === 2 ? lastDateOfMonth : (duration_type === 3 ? weeks(7, getMySqlDate(start_date)) : (duration_type === 4 ? momentDate(addMonthinDate(getKenyanDateTime(), 3)) : end_date))),
    });
    setCheckinDetail(checkIn)
    let total = size(checkIn?.checkInDetail)
    const ontimeCheckins = filter(checkIn?.checkInDetail, { punch_action_status: 'ontime' }).length;
    setTotalDays(total)
    setOntime(ontimeCheckins)
    let average = Math.floor(ontimeCheckins*100  / total)
    setAvg(average ? average : 0)
    setLoading(false)
    })
  }

  const validateDateRange = (date) => {
    const initialdate = getinitialdate();
    const endDate = moment(form.getFieldValue('end_date'));
    if (endDate < date) {
      form.setFieldsValue({ 'end_date': date })
    }
    getCheckinList()
  }

  function weeks(days, date) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date.toISOString().slice(0, 10);
  }

  const containerRef = useRef(null);

  const prepareCSV = async (event, done) => {
    if (checkinDetail.checkInDetail.length > 0) {
      setCSVcheckinList(

        reduce(checkinDetail.checkInDetail, function (result, record) {
          result.push({
            "Date": `${moment(record?.date).format("Do MMM YYYY")}`,
            "Day": `${moment(record?.date).format("dddd")}`,
            "Check-in time": `${moment(record?.checkinTime).format('LT')}`,
          });
          return result;
        }, [])
      );

      setTimeout(() => {
        csvLinkEl.current.link.click();
      });
    } else {
      message.error(`No data available to download.`);
    }
  }

  return (
    <>      
      <StyledTitle>
                CHECK IN REPORT 
      </StyledTitle>
      <StyledChartContainer>
      <div className='name-title'>
      <Col xs={5} sm={19} md={15} className='page-title-head' ref={containerRef}>
          <Space>
            <StyledApprovalSearchForm
              form={form}
              name='approval_form'
              layout='inline'
              onChange={getCheckinList}
              initialValues={{
                duration_type: 2,
                month: dateInDetail(getKenyanDateTime()).monthName,
                start_date: momentDate(addMonthinDate(getKenyanDateTime(),-1)),
                end_date: momentDate(addDaysinDate(getKenyanDateTime(),)),
              }}
              onFinish={getCheckinList}
            >
              <Form.Item
                name='duration_type'
                rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]}
              >
                <Select
                  placeholder='Select...'

                 className='select-width'
                  options={CHECKIN_REPORT_FILTER_TYPES}
                  onChange={getCheckinList}
                  getPopupContainer={() => containerRef.current}
                />
              </Form.Item>

              <Form.Item
                className='outer-form'
                shouldUpdate
              >
                {
                  ({ getFieldValue }) => {
                    const duration_type = getFieldValue('duration_type');
                    return duration_type === 1 ? (
                      <>
                        <Row>
                          <Col>

                            <Form.Item name='start_date'
                              rules={[
                                () => ({
                                  validator(rule, value) {
                                    const date = moment(value);
                                    return date.isValid() ? Promise.resolve() : Promise.reject();
                                  }
                                })
                              ]}
                            >
                              <DatePicker
                                className='date_picker'
                                placeholder='Start date'
                                onChange={validateDateRange}
                                allowClear={false}
                              />
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item
                              name='end_date'
                              rules={[
                                () => ({
                                  validator(rule, value) {
                                    const date = moment(value);
                                    return date.isValid() ? Promise.resolve() : Promise.reject();
                                  }
                                })
                              ]}
                            >
                              <DatePicker
                                className='date_picker'
                                placeholder='End date'
                                onChange={validateDateRange}
                                allowClear={false}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    ) : ((duration_type === 3) ? <Form.Item name='start_date'
                      rules={[
                        () => ({
                          validator(rule, value) {
                            const date = moment(value);
                            return date.isValid() ? Promise.resolve() : Promise.reject();
                          }
                        })
                      ]}
                    >
                      <DatePicker
                        className='date_picker'
                        placeholder='Start date'
                        onChange={validateDateRange}
                        allowClear={false}
                      />
                    </Form.Item> : ((duration_type === 2) ? (
                      <Form.Item
                        name='month'
                        rules={[
                          {
                            required: true,
                            message: '',
                          },
                        ]}
                      >
                        <Select
                          className='date_picker'
                          placeholder='Select...'
                          options={monthList}
                          onChange={getCheckinList}
                          getPopupContainer={() => containerRef.current}
                        />
                      </Form.Item>
                    ) :
                      ""
                    ))
                  }
                }
              </Form.Item>


            </StyledApprovalSearchForm>
          </Space>
        </Col>
        <p>Average on-time rate <span className='span-color'>{avg}%</span></p>
          
      </div>
      <Spin spinning={loading}>
          <Col md={24} sm={24} xs={24} xl={24} >
            <Tooltip placement="bottom" title={`${ontime}/${totalDays} days on-time`}>
              <Progress percent={avg} strokeColor={'#A33CFC'} trailColor={'#D5A5FF'} strokeWidth ={15} showInfo={false}/>
            </Tooltip>
          </Col>
          <div>
          <StyleRow >
              <Col  md={12} sm={12} xs={12} lg={12} xl={12} className='title'>Check-in Time</Col>    
              <Col xs={24} sm={12} md={12} className='align-right'>
                <StyledButton3 onClick={prepareCSV} disabled={checkinDetail.checkInDetail?.length === (0 || null || undefined)}>
                  Export CSV file
                </StyledButton3>
                <CSVLink
                  header={CSVHeaders}
                  data={CSVcheckinList}
                  filename={"check-in report.csv"}
                  asyncOnClick={true}
                  ref={csvLinkEl}
                />
              </Col>
            </StyleRow>  
            <Col md={24} sm={24} xs={24} xl={24} className='styled-col' >
              <List
                size="small"
                bordered={false}
                dataSource={checkinDetail?.checkInDetail}
                renderItem={(item) =>
                  <List.Item className='d-flex justify-content-space-between align-center' >
                    {moment(item.date).format('DD-MM-YYYY')}
                    <span className='dash-line'/>
                  {(moment(item.checkinTime).format('LT'))}
                  </List.Item>
                }
                className='list-style'
              />
            </Col>  
          </div>
        </Spin>
      </StyledChartContainer>
    </>
  );
}

const mapStateToProps = state => ({
	userInfo: getUserInfo(state),
	userList: getUserList(state),
  isCheckedIn: getIsCheckedIn(state),
});


export default connect(mapStateToProps)(UserCheckinReport)