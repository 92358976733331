import { get } from 'lodash';
import {
  USERNAME,
  TOGGLE_DRAWER,
  USER_ROLE,
  IS_LOGGED_IN,
  SET_DEPARTMENT_ID,
  SET_SYSTEM_DATE,
  SET_LOCATIONS,
  SET_BREAK_TYPES,
  SET_CHECK_IN_DATE_TIME,
  SET_CHECK_OUT_DATE_TIME,
  SET_IS_ON_BREAK,
  TOGGLE_SUCCESS_MODAL,
  SET_USER_LIST,
  TOGGLE_PROCESSING_MODAL,
  LOADING_DATE_FIRST_TIME,
  SET_DEPARTMENT_LIST,
  SET_LEAVE_CATEGORY_LIST,
  SET_PUNCH_ACTIONS,
  SET_ROLE_LIST,
  CLEAR_STORE,
  SET_IS_CHECKED_IN,
  SET_CHECK_IN_LOCATION_ID,
  SET_STATUS_LIST,
  SET_ALL_DEPARTMENT_LIST,
  SET_BREAK_ID,
  SET_PUNCH_ID,
  SET_SHIFT_TIME_MASTER,
  SET_NOTIFICATION_LIST,
  SET_WEB_SOCKET_STATUS,
  USER_PROFILE,
  SET_CHAT_MESSAGE_LIST,
  SET_CHAT_SELECTED_STAFF_ID,
  SET_CHAT_MESSAGE_QUEUE,
  SET_UNREAD_MESSAGE_INDICATOR,
  PROFILE_PIC
} from '../actionsTypes';

const initialState = {
  userInfo: {
    name: '',
    roleId: 0,
    location_name: '',
    joining_date: '',
    username: '',
    email_id: '',
    job_title: '',
    department: '',
    payroll: '',
    employer_id:'',
    national_Id: '',
    staff_department_id: '',
    gender: '',
    sick_leave_halfpay: '',
    rest_days: '',
  },
  webSocketStatus: 0,
  roleName: '',
  toggleDrawer: false,
  isLoggedIn: false,
  selectedDepartmentId: [],
  date: '',
  checkInDateTime: '',
  checkOutDateTime: '',
  isCheckedIn: false,
  checkInLocationId: '',
  isOnBreak: false,
  breakId: '',
  punchId: '',
  displaySuccessModal: false,
  successModalMessage: '',
  displayProcessingModal: false,
  loadingDataFirstTime: true,
  departmentList: [],
  allDepartmentList: [],
  locationList: [],
  breakTypeList: [],
  userList: [],
  leaveCategoryList:[],
  punchActions: [],
  roleList: [],
  shiftTimeMaster: [],
  holidayList: [],
  notificationList: [],
  statusList: [],
  chatMessageList: [],
  chatSelectedStaffId: '',
  chatMessageQueue: [],
  newMessageIndicatorList: [],
  profilePic: ''
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USERNAME:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          name: action.payload
        },
      };
    case USER_ROLE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          roleId: action.payload
        },
      }
    case USER_PROFILE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          location_name: get(action.payload, 'location_name', ''),
          joining_date: get(action.payload, 'joining_date', ''),
          username: get(action.payload, 'username', ''),
          email_id: get(action.payload, 'email_id', ''),
          job_title: get(action.payload, 'job_title', ''),
          department: get(action.payload, 'department', ''),
          payroll: get(action.payload, 'payroll', ''),
          employer_id: get(action.payload, 'employer_id', ''),
          national_Id: get(action.payload, 'national_Id', ''),
          staff_department_id: get(action.payload, 'staff_department_id', ''),
          gender: get(action.payload, 'gender', ''),
          sick_leave_halfpay: get(action.payload, 'sick_leave_halfpay', ''),
          rest_days: get(action.payload, 'rest_days', ''),
        }
      }
    case TOGGLE_DRAWER:
      return {
        ...state,
        toggleDrawer: action.payload,
      };
    case IS_LOGGED_IN:
        return {
          ...state,
          isLoggedIn: action.payload,
        };
    case SET_DEPARTMENT_ID:
        return {
          ...state,
          selectedDepartmentId: action.payload,
        }
    case SET_SYSTEM_DATE:
      return {
        ...state,
        date: action.payload,
      }
    case SET_LOCATIONS:
      return {
        ...state,
        locationList: action.payload,
      }
    case SET_BREAK_TYPES:
      return {
        ...state,
        breakTypeList: action.payload,
      }
    case SET_CHECK_IN_DATE_TIME:
      return {
        ...state,
        checkInDateTime: action.payload,
      }
    case SET_CHECK_OUT_DATE_TIME:
      return {
        ...state,
        checkOutDateTime: action.payload,
      }
    case SET_IS_ON_BREAK:
      return {
        ...state,
        isOnBreak: action.payload,
      }
    case TOGGLE_SUCCESS_MODAL:
      return {
        ...state,
        displaySuccessModal: action.payload.visibility,
        successModalMessage: action.payload.message
      }
    case SET_USER_LIST:
      return {
        ...state,
        userList: action.payload
      }
    case TOGGLE_PROCESSING_MODAL:
      return {
        ...state,
        displayProcessingModal: action.payload
      }
    case LOADING_DATE_FIRST_TIME:
      return {
        ...state,
        loadingDataFirstTime: action.payload
      }
    case SET_DEPARTMENT_LIST:
      return {
        ...state,
        departmentList: action.payload
      }
    case SET_LEAVE_CATEGORY_LIST:
      return {
        ...state,
        leaveCategoryList: action.payload
      }
    case SET_PUNCH_ACTIONS:
      return {
        ...state,
        punchActions: action.payload
      }
    case SET_ROLE_LIST:
      return {
        ...state,
        roleList: action.payload
      }
    case SET_IS_CHECKED_IN:
      return {
        ...state,
        isCheckedIn: action.payload
      }
    case SET_CHECK_IN_LOCATION_ID:
      return {
        ...state,
        checkInLocationId: action.payload
      }
    case SET_STATUS_LIST:
      return {
        ...state,
        statusList: action.payload
      }
    case SET_ALL_DEPARTMENT_LIST:
      return {
        ...state,
        allDepartmentList: action.payload
      }
    case SET_PUNCH_ID:
      return {
        ...state,
        punchId: action.payload
      }
    case SET_BREAK_ID:
      return {
        ...state,
        breakId: action.payload
      }
    case SET_SHIFT_TIME_MASTER:
      return {
        ...state,
        shiftTimeMaster: action.payload
      }
    case SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload
      }
    case SET_WEB_SOCKET_STATUS:
      return {
        ...state,
        webSocketStatus: action.payload
      }
    case SET_CHAT_MESSAGE_LIST:
      return {
        ...state,
        chatMessageList: action.payload
      }
    case SET_CHAT_SELECTED_STAFF_ID:
      return {
        ...state,
        chatSelectedStaffId: action.payload
      }
    case SET_CHAT_MESSAGE_QUEUE:
      return {
        ...state,
        chatMessageQueue: action.payload
      }
    case SET_UNREAD_MESSAGE_INDICATOR:
      return {
        ...state,
        newMessageIndicatorList: action.payload
      }
    case PROFILE_PIC:
      return {
        ...state,
        profilePic : action.payload
      }  
    case CLEAR_STORE:
      return {
        ...initialState        
      }
    default:
      return state;
  }
}
