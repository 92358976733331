import React, { useEffect, useLayoutEffect, useState } from "react";
import { Layout } from "antd";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import styled from "styled-components";
// Components
import SideBar from "molecules/SideMenu";
import Header from "molecules/Header";
import Login from "pages/Login";
import Container from "pages/Container";
import { STATUS_CODE } from "common/Constants";
// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setIsUserLoggedIn,
  setSystemDate,
  setUsernName,
  setLoadingDataFirstTime,
  setBreakTypesList,
  setDepartmentList,
  setLeaveCategoryList,
  setLocationList,
  setPunchActions,
  setUserRole,
  setDepartmentId,
  setRoleList,
  setCheckedIn,
  setCheckInLocationId,
  setIsOnBreak,
  setUserList,
  setStatusList,
  setAllDepartmentList,
  setPunchId,
  setBreakId,
  toggleProcessingModal,
  setShiftTimeMaster,
  setUserProfile,
  clearStore,
  setProfilePic,
} from "redux/actions";
import { getIsUserLoggedIn, getUserName, getSysteDate } from "redux/selectors";
import ProcessingModal from "molecules/ProcessingModal";

import { get, first } from "lodash";
import { CALL_API } from "common/API";
import { getServerDateToMySql } from "utils/Date";
import PrivacyAndPolicy from "pages/PrivacyAndPolicy";

const StyledAppDiv = styled.div`
  height: 100%;
`;

const App = ({
  activePage,
  isUserLoggedIn,
  setUserLoggedIn,
  saveSystemDate,
  setBreakTypes,
  saveDepartmentList,
  saveAllDepartmentList,
  saveLeaveCategoryList,
  saveLocationList,
  savePunchActions,
  saveUsernName,
  toggleLoadingDataFirstTime,
  saveDepartmentId,
  saveRoleList,
  updateCheckInLocationId,
  updateIsOnBreak,
  updateCheckedIn,
  updateUserList,
  saveStatusList,
  savePunchId,
  saveBreakId,
  updateProcessingModal,
  saveShiftTimeMaster,
  saveUserProfile,
  removeStoreData,
  saveProfilePic,
}) => {
  const [pageTitle, setPageTitle] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [intervalID, setIntervalID] = useState();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const endpoint = window.location.pathname;

  const onClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setCollapsed(!collapsed);
  };

  const isLoggedIn = () => {
    setUserLoggedIn(!isUserLoggedIn);
    toggleLoadingDataFirstTime(true);
    fetchMasterData(true);
    getPreview();
  };

  const updatePageTitle = (title) => {
    setPageTitle(title);
  };

  let defaultKey = "";

  useEffect(() => {
    if (isUserLoggedIn) {
      const intId = setInterval(() => {
        saveSystemDate(startTimeCounter());
      }, 1000);
      setIntervalID(intId);
      setUserLoggedIn(true);
      fetchMasterData(false);
      getPreview();
    } else {
      clearInterval(intervalID);
    }
  }, [isUserLoggedIn]);

  useLayoutEffect(() => {
    clearInterval(intervalID);
    updateProcessingModal(false);
    if (window.innerWidth < 567) {
      toggleDrawer();
    }
  }, []);

  const startTimeCounter = () => {
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    const nd = new Date(utc + 3600000 * +3);
    return getServerDateToMySql(nd.toLocaleString("en-US"));
  };

  const fetchMasterData = async (fetch_master_data) => {
    const {
      code,
      first_name,
      last_name,
      breakList,
      roleList,
      departmentList,
      departmentsAssignedToUser,
      leaveCategories,
      locations,
      punchActions,
      location_id,
      punch_action_id,
      userList,
      statusList,
      shiftTimeMaster,
      break_id = "",
      location_name,
      joining_date,
      username,
      email_id,
      job_title,
      department,
      employer_id,
      payroll,
      national_Id,
      staff_department_id,
      gender,
      sick_leave_halfpay,
      rest_days,
    } = await CALL_API("master-data", "post", {
      fetch_master_data,
    });
    if (code === STATUS_CODE.SUCCESS) {
      if (fetch_master_data) {
        setBreakTypes(breakList);
        saveDepartmentList(departmentsAssignedToUser);
        saveAllDepartmentList(departmentList);
        saveDepartmentId([
          get(first(departmentsAssignedToUser), "department_id", ""),
        ]);
        saveLeaveCategoryList(leaveCategories);
        saveLocationList(locations);
        savePunchActions(punchActions);
        saveUsernName(`${first_name} ${last_name}`);
        saveRoleList(roleList);
        updateUserList(userList);
        saveStatusList(statusList);
        saveShiftTimeMaster(shiftTimeMaster);
        saveUserProfile({
          location_name,
          joining_date,
          username,
          email_id,
          job_title,
          department,
          payroll,
          national_Id,
          employer_id,
          staff_department_id,
          gender,
          sick_leave_halfpay,
          rest_days,
        });
      }
      updateCheckInLocationId(location_id);
      updateIsOnBreak(break_id ? true : false);
      saveBreakId(break_id);
      savePunchId(punch_action_id);
      updateCheckedIn(punch_action_id ? true : false);
      toggleLoadingDataFirstTime(false);
      if (punch_action_id) {
        window.localStorage.setItem("login_reminder", "true");
      }
    } else {
      window.localStorage.removeItem("Tymeplusjwt");
      window.localStorage.removeItem("login_reminder");
      setUserLoggedIn(false);
      removeStoreData();
    }
  };

  const getPreview = async () => {
    const { code, url } = await CALL_API("profile-view", "post", {});
    if (code === STATUS_CODE.SUCCESS) {
      saveProfilePic(url);
    }
  };

  return (
    <StyledAppDiv>
      {isUserLoggedIn && (
        <>
          <Router>
            <Layout isapp={`${navigator.userAgent === "application"}`}>
              <SideBar
                open={open}
                onClose={onClose}
                setPageTitle={updatePageTitle}
                defaultKey={defaultKey}
                activePage={activePage}
                collapsed={collapsed}
                toggleCollapse={toggleDrawer}
              ></SideBar>
              <Layout>
                <Header
                  showDrawer={showDrawer}
                  pageTitle={pageTitle}
                  setLoggedIn={isLoggedIn}
                  setPageTitle={updatePageTitle}
                  toggleCollapse={toggleDrawer}
                  collapsed={collapsed}
                ></Header>
                <Container />
              </Layout>
            </Layout>
          </Router>
        </>
      )}
      {!isUserLoggedIn && endpoint != "/privacy-policy" && (
        <Login setLoggedIn={isLoggedIn} />
      )}
      {!isUserLoggedIn && endpoint == "/privacy-policy" && (
        <Router>
          <Route path="/privacy-policy">
            <PrivacyAndPolicy />
          </Route>
        </Router>
      )}

      <ProcessingModal />
    </StyledAppDiv>
  );
};

const mapStateToProps = (state) => ({
  isUserLoggedIn: getIsUserLoggedIn(state),
  userName: getUserName(state),
  systeDate: getSysteDate(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUserLoggedIn: setIsUserLoggedIn,
      saveSystemDate: setSystemDate,
      saveUsernName: setUsernName,
      setBreakTypes: setBreakTypesList,
      saveDepartmentList: setDepartmentList,
      saveAllDepartmentList: setAllDepartmentList,
      saveLeaveCategoryList: setLeaveCategoryList,
      saveLocationList: setLocationList,
      savePunchActions: setPunchActions,
      saveUserRole: setUserRole,
      toggleLoadingDataFirstTime: setLoadingDataFirstTime,
      saveDepartmentId: setDepartmentId,
      saveRoleList: setRoleList,
      updateCheckedIn: setCheckedIn,
      updateCheckInLocationId: setCheckInLocationId,
      updateIsOnBreak: setIsOnBreak,
      updateUserList: setUserList,
      saveStatusList: setStatusList,
      savePunchId: setPunchId,
      saveBreakId: setBreakId,
      updateProcessingModal: toggleProcessingModal,
      saveShiftTimeMaster: setShiftTimeMaster,
      saveUserProfile: setUserProfile,
      removeStoreData: clearStore,
      saveProfilePic: setProfilePic,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
